import moment from 'moment'

const axios = require('axios')

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let algoexplorerapi_endpoint
if(providerEnv === "MainNet") {
  algoexplorerapi_endpoint = 'https://algoexplorerapi.io'
} else {
  algoexplorerapi_endpoint = 'https://testnet.algoexplorerapi.io'
}

/*
 * Compute the the days, hours, minutes, and seconds between two moments
 * @param {Object} momA The first moment
 * @param {Object} momB The seconds moment
 * @param {Array} Days, hours, minutes, and seconds betweentwo moments 
 */
export const getMomentDiff = (aMom) => {
  let mom = [
    ['days', 0],
    ['hours', 0],
    ['minutes', 0],
    ['seconds', 0]
  ]
  let diff = mom.map(([head,]) => head).map((el, index) => {
    let moms = mom.slice(0, index)
    let period = aMom.diff(
      moms.reduce((acc, [name, val]) => {
        return acc.add(val, name)
      }, moment()), el)
    mom[index] = [el, period]
    //console.log({mom, moms, period})
    return [el, period]
  })
  //console.log({diff})
  return diff
}

export const formatCompactAddress = (address) =>
  String(address).substr(0, 5).concat("...").concat(String(address).substr(-5))

export const zip = (a, b) => a.map((k, i) => [k, b[i]])

const somethingFromSome = (f, d) => (some) => some[1] ? f(some[1]) : d

export const formatCurrencyFromSome = (stdlib, some) =>
  somethingFromSome((some) => stdlib.formatCurrency(some, 4), 0)(some)

export const bigNumberToNumberFromSome = (stdlib, some) =>
  somethingFromSome((some) => stdlib.bigNumberToNumber(some), 0)(some)

export const formatAddressFromSome = (stdlib, some) =>
  somethingFromSome((some) => stdlib.formatAddress(some), 0)(some)

export const getUrlPath = (url) =>
  ((delimeter) =>
    url.split(delimeter).slice(2).join(delimeter))
    ('/')

export const getAssetTest =
  async (assetIndex) => {
    let asset = {}
    try {
      let res = await getAsset(assetIndex)
      if (res.status === 200) {
        asset = res.data
      }
    } catch (e) { }
    return asset
  }

export const getAccountInfo = async (addr) => {
  return (
    await axios.get(`${algoexplorerapi_endpoint}/v2/accounts/${addr}`)
  )?.data;
};
export const getAccInfo =
  async (address) =>
    await axios.get(`${algoexplorerapi_endpoint}/v2/accounts/${address}`)

export const getAsset =
  async (assetIndex) =>
    await axios.get(`${algoexplorerapi_endpoint}/v1/asset/${assetIndex}`)

export const getAppInfo =
  async (appId) =>
    await axios.get(`${algoexplorerapi_endpoint}/v2/applications/${appId}`)

export const getAppInfo2 =
  (appId) =>
    axios.get(`${algoexplorerapi_endpoint}/v2/applications/${appId}`)

export const getBlock = (block) =>
    axios.get(`${algoexplorerapi_endpoint}/idx2/v2/blocks/${block}`)

export const getAccountTransaction = async (addr, params) =>
  await axios.get(`${algoexplorerapi_endpoint}/idx2/v2/accounts/${addr}/transactions`, params)

export const getCFIPFSUrl = (path) =>
  `https://cloudflare-ipfs.com/ipfs/${path}`

export const getCFIPFS =
  async (path) =>
    await axios.get(getCFIPFSUrl(path))

const placeholderImage = 'https://via.placeholder.com/1024'
export const getAssetImage =
  async (assetIndex) => {
    let image
    try {
      const asset = await getAsset(assetIndex)
      console.log({asset})
      const { url } = asset.data
      if(url === undefined)
        return placeholderImage
      //console.log({url})
      let res = await axios.get(url)
      //console.log({res})
      let contentType = res?.headers['content-type'] ?? ""
      //console.log({contentType})
      switch(contentType) {
        case 'image/jpeg':
        case 'image/png':
        case 'image/gif':
          image = url
          return image
        case 'text/json':
          break
        default:
          throw "html"
      }
      //console.log({res, contentType, url})
      if (url.indexOf('ipfs') !== -1) {
        if (url.indexOf('json') !== -1) {
          // url is metadata json with relative path to image
          const maybeImage = (await getCFIPFS(getUrlPath(url))).data
          console.log({maybeImage})
          var path = require('path')
          image = [path.dirname(url), maybeImage.image].join('/')
        } else {
          if (url.indexOf('pinata') === -1) {
            // TODO url is another ipfs resource
            const maybeMetadata = (await getCFIPFS(getUrlPath(url))).data
            if (maybeMetadata.image.indexOf('ipfs') !== -1) {
              // use gateway url
              image = getCFIPFSUrl(getUrlPath(maybeMetadata.image))
            } else {
              // use url as is
              image = maybeMetadata.image
            }
          } else {
            // pinata url contains image
            image = url
          }
        }
      } else {
        // url may be gateway url
        image = url
      }
    } catch (e) {
      console.log(e)
      // use placeholder as fallback
      image = placeholderImage
    }
    return image
  }

/*
 * transactions
 */

export const getBidHistory = (stdlib, transactions, addr) =>
  ((transactions) =>
    transactions
      .filter(el => true
        && el['tx-type'] === 'pay'
        && el['payment-transaction'].receiver === addr)
      .map((el) => ({
        time: moment.unix(el['round-time']).format('LTS'),
        sender: el?.sender,
        amount: stdlib.formatCurrency(el["payment-transaction"]?.amount, 4)
      }))
      .slice(0, -3))
    (transactions)

/* 
* isAuctionOver
* if payment transactions platform address 
* then auction is over
*/
export const isAuctionOver = (transactions, addr) =>
  ((transactions =>
    transactions
      .map(el => el["payment-transaction"]?.receiver ?? "")))
    (transactions)
    .some(el => el === addr)
