import React, { useEffect, useMemo, useState } from "react"
import moment from 'moment';
import {
  useParams,
  useHistory,
} from "react-router-dom";
import {
  getAccountInfo,
  getAppInfo,
  getAccountTransaction,
} from '../functions'
import { useSnackbar } from 'notistack';
import AuctionLoader from '../loaders/AuctionLoader';
import ContractLoader from "../loaders/ContractLoader";
import AuctionView from '../views/AuctionView'
import Card from '../components/Card'
import { Image, Row, Spinner } from "react-bootstrap";
import GalleryCard from "../components/GalleryCard";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import styles from './auction.module.css'
const auctionStyle =
{
  "margin": "auto",
  "paddingBottom": "100px"
}
const headingStyle =
{
  "height": "38px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "900",
  "fontSize": "32px",
  "lineHeight": "38px",
  "textAlign": "center",
  "letterSpacing": "0.1em",
  "textTransform": "uppercase",
  "color": "#2A3035",
  "opacity": "0.8",
}
const Browse = (props) => {
  const history = useHistory()
  const WAValidator = require('@swyftx/api-crypto-address-validator')
  document.title = "Auction - NFT Jam"
  const {
    stdlib,
    acc,
    approvalProgram,
    firstBlock,
    ADDR_PLATFORM,
    ADDR_DISCOVERY
  } = props
  let appId = 'all'
  const [app, setApp] = useState(null)
  const [apps, setApps] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [state, setState] = useState({})
  const getApps =
    useMemo(() => async (addr, block, program) => {
      let hash = []
      let transactions = Array(...((await getAccountTransaction(addr, {
        ["min-round"]: firstBlock
      }))?.data?.transactions ?? []))
      let a = new Set(transactions.filter(el => el['confirmed-round'] > firstBlock).map(el => el.sender))
      Array.from(a).forEach(el => hash[el] = 1)
      let b = new Set((await Promise.all(Array.from(a).flatMap(async el => {
        let transactions = Array(...((await getAccountTransaction(el, {
          ["min-round"]: firstBlock
        }))?.data?.transactions ?? []))
        let a = Array.from(new Set(transactions.filter(el => el['confirmed-round'] > block && !(el.sender in hash)).map(el => el.sender)))
        return a
      }))).flatMap(el => el))
      let c = await Promise.all(Array.from(b).flatMap(async el => {
        let accInfo = await getAccountInfo(el)
        let apps = accInfo['created-apps']
          .filter(app => app?.params['approval-program'] === program)
          .map(({ id }) => id)
        return apps
      }))
      console.log({ transactions, a, b, c: c.flatMap(el => el) })
      return c.flatMap(el => el)
    }, [ADDR_DISCOVERY, firstBlock, approvalProgram])
  useEffect(() => {
    if (!apps) {
      (async () => {
        getApps(ADDR_DISCOVERY, firstBlock, approvalProgram)
          .then(apps => setTimeout(() => setApps(apps), 2000))
      })()
    }
  }, [])
  const handleGalleryClick = (id) => {
    history.push(`/auction/${id}`)
    return
    if (!app) {
      setApp(id)
    }
    else {
      setApp(null)
      setTimeout(() => setApp(id), 1000)
    }
  }
  const buttons = <>
    <Button>LIVE</Button>
    {false && <Button disabled>CLOSED</Button>}
  </>
  return <div id="browse" style={auctionStyle}>
    {false && <ButtonGroup style={{ marginBottom: "50px" }} size="large" aria-label="large secondary button group">
      {buttons}
    </ButtonGroup>}
    {acc && app && <ContractLoader acc={acc} id={app} stdlib={stdlib} approvalProgram={approvalProgram} firstBlock={firstBlock}>
      <AuctionLoader platformAddr={ADDR_PLATFORM}>
        <AuctionView acc={props.acc} id={app} {...state.view} v={state.v} loading={state.loading} closed={state.closed} joined={state.joined} closing={state.closing} address={props?.acc?.address} caughtUp={state.caughtUp} showJoin={props.ADDR_MNEMONIC === props?.acc?.address} />
      </AuctionLoader>
    </ContractLoader>}
    {acc && apps && <Row className={styles.container}>
      {apps.map((id, index) =>
        <ContractLoader key={`contract${index}`} acc={acc} id={id} stdlib={stdlib} approvalProgram={approvalProgram} firstBlock={firstBlock} showLoading={false}>
          <AuctionLoader platformAddr={ADDR_PLATFORM}>
            <GalleryCard className={styles.itemA} onClick={handleGalleryClick} onLoaded={()=>setLoaded(true)} />
          </AuctionLoader>
        </ContractLoader>)}
    </Row>}
    {!loaded && <div style={{
      ...headingStyle,
      "fontWeight": "100",
      "color": "white",
    }}>
      <Spinner
        as="span"
        animation="grow"
        size="lg"
        role="status"
        aria-hidden="true"
      />
      Loading Live Auctions...
    </div>}
  </div>
}

export default Browse