import { createContext } from "react"
export const features = {
  production: {
    sellPage: true,
    browsePage: false,
    myAuctions: true,
  },
  test: {
    sellPage: true,
    browsePage: true,
    myAuctions: true,
  },
  dev: {
    sellPage: true,
    browsePage: !true,
    myAuctions: true,
  }
}
export const FeatureFlagContext = createContext(features.dev)