import BidButton from "./BidButton";
import CloseButton from "./CloseButton";
import ClosingButton from "./ClosingButton";
import ClaimingButton from "./ClaimingButton";
import LoadingButton from "./LoadingButton";
import { useEffect, useState } from "react";
import { bigNumberToNumberFromSome, formatCurrencyFromSome, getAccInfo } from "../../functions";
const APIButtons = ({
  acc,
  address,
  views,
  apis,
  showing,
  stdlib,
  closed,
  bidDefaultValue,
  startPrice,
}) => {
  const [bid, setBid] = useState(bidDefaultValue)
  const [state, setState] = useState({
    loading: false,
    bid: bidDefaultValue,
    showing
  })
  // EFFECT: UPDATE MIN BID
  useEffect(() => {
    const interval = setInterval(() => {
      Promise.all([
        views.minBid(),
      ])
        .then(([
          minBid,
        ]) => {
          const fMinBid = formatCurrencyFromSome(stdlib, minBid)
          if (parseFloat(fMinBid) > parseFloat(`${bid}`)) {
            setBid(fMinBid)
          }
        })
    }, 4000)
    return () => clearInterval(interval)
  }, [bid])
  const ACTION = {
    optin: (payload) => {
      Promise.all([
        getAccInfo(address),
        views.token()
      ])
        .then(([{ data }, token]) => {
          let { assets } = data
          let formattedToken = bigNumberToNumberFromSome(stdlib, token)
          if (!assets.some(el => el['asset-id'] === formattedToken)) {
            acc.tokenAccept(formattedToken)
              .then(() => {
                payload()
              })
          } else {
            payload()
          }
        })
    },
    claim: () => {
      ACTION.optin(() => {
        setState({ ...state, loading: true })
        apis.Bid.close(null)
          .then(() => {
            /*
            onClose()
            enqueueSnackbar('NFT claimed!', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            })
            */
          })
          .catch((e) => setState({ ...state, loading: false })) // user doesn't sign
      })
    },
    close: () => {
      setState({ ...state, loading: true })
      apis.Bid.close(null)
        .then(() => {
          /*
          onClose()
          enqueueSnackbar('Auction closed!', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          })
          */
          setState({ ...state, loading: false, over: true })
        })
        .catch((e) => setState({ ...state, loading: false })) // user doesn't sign
    },
    bid: async () => {
      let bal = await stdlib.balanceOf(acc)
      let assetCount = ((await getAccInfo(acc?.networkAccount?.addr ?? ""))?.data?.assets ?? []).length
      let minBid = await views.minBid()
      let fBal = Math.round(10 * (parseFloat(stdlib.formatCurrency(bal)) - /*minimum balance*/assetCount * 0.1 - 0.1)) / 10
      let fMinBid = parseFloat(formatCurrencyFromSome(stdlib, minBid))
      let fBid = Math.round(parseFloat(bid) * 10000) / 10000
      if (fBid < fMinBid) {
        alert(`Bid too low: must be greater or equal to ${fMinBid}`)
        return
      }
      else if (fBal <= fBid) {
        alert(`Insuffient balance: must be greater than or equal to bid amount`)
        return
      }
      setState({ ...state, loading: true })
      apis.Bid.getBid(stdlib.parseCurrency(bid))
        .then((msg) => {
          Promise.all([
            views.currentPrice(),
          ])
            .then(([cp]) => [parseFloat(formatCurrencyFromSome(stdlib, cp)) + 1])
            .then(([cp]) => {
              setBid(cp)
              setState({ ...state, show: "bid", bid: cp })
            })
        })
        .catch((e) => setState({ ...state, loading: false })) // user doesn't sign
    }
  }
  const handleChange = ({ target }) => setBid(target.value)
  let button = () => {
    switch (showing) {
      case 'loading':
        return <LoadingButton />
      case 'bid':
        return state.loading
          ? <LoadingButton />
          : <BidButton onClick={ACTION["bid"]} onChange={handleChange} value={bid} />
      case 'close':
        return state.loading
          ? <ClosingButton />
          : state.over
            ? ""
            : <CloseButton onClick={ACTION["close"]} />
      case 'claim':
        return state.loading
          ? <ClaimingButton />
          : state.over
            ? ""
            : <CloseButton variant="claim" onClick={ACTION["claim"]} />
      case 'claiming':
        return <LoadingButton variant="claim" />
      case 'closing':
        return <ClosingButton />
      case 'closed':
      default:
        return ""
    }
  }
  return <>
    {/*state.loading ? <LoadingButton /> : button()*/}
    {!closed ? button() : ""}
  </>
}
export default APIButtons;