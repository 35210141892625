import { FeatureFlagContext } from '../context/FeatureFlagContext';
import { useState, useEffect } from 'react'
import * as backendBank from '../build/bank.main.mjs'
import {
  useHistory,
  useLocation
} from "react-router-dom";
import wallet from '../wallet.svg';
import logo from '../logo.svg';
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Icon from "react-crypto-icons";
import { formatCompactAddress } from '../functions';
import useCopy from '../hooks/useCopy';
import styles from './navbar.module.css'

const axios = require('axios')

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let algoexplorerapi_endpoint
if(providerEnv === "MainNet") {
  algoexplorerapi_endpoint = 'https://algoexplorerapi.io'
} else {
  algoexplorerapi_endpoint = 'https://testnet.algoexplorerapi.io'
}

const AccountInfo = ({
  style,
  acc,
  stdlib,
  handleDisconnect,
}) => {
  let { address } = acc
  const [bal, setBal] = useState(Number(acc.amount / 1000000).toFixed(2))
  const copy = useCopy()
  useEffect(() => {
    let interval = setInterval(() => {
      console.log(new Date())
      stdlib.balanceOf(acc)
        .then(newBal => stdlib.formatCurrency(newBal, 2))
        .then(newBal => {
          if (bal !== newBal) {
            setBal(newBal)
          }
        })
    }, 5000)
    return () => clearInterval(interval)
  }, [])
  const rstyle =
  {
    "margin": "auto"
  }
  const iconStyle = {
    "fontSize": "17px",
    "marginLeft": "8px",
    "verticalAlign": "sub"
  }
  return <>
    <span className="d-none d-md-inline-block" style={{ ...style, ...rstyle, "fill": "white" }}>
      <Icon name="algo" size={11} />&nbsp;{bal}
    </span>
    {((addr) => <span style={{ ...style, ...rstyle }}>
      <span onClick={() => copy(addr)}>{formatCompactAddress(addr)}</span>
      <ExitToAppIcon role="button" onClick={handleDisconnect} className="app-info-exit-icon" style={{ ...style, ...rstyle, ...iconStyle }} />
    </span>)(address)}
  </>
}

const NFTJamNavbar = (props) => {
  let {
    acc,
    handleConnect,
    stdlib,
    APP_ID_BANK,
    ASSET_ID_JAM
  } = props
  let location = useLocation()
  let history = useHistory()
  const [optin, setOptin] = useState(false)
  const [loadingJam, setLoadingJam] = useState(false)
  const [state, setState] = useState({
    bank: null,
    buy: null,
    viewAmount: 0
  })
  /*
  useEffect(() => {
    if (state.bank && state.viewAmount > 0) {
      (async () => {
        const interval = setInterval(() => {
          (async () => {
            const viewAmount = bigNumberToNumberFromSome(await (await state.bank.getViews()).Bank.amount())
            if(state.viewAmount !== viewAmount) {
              state.buy(false)
            }
          })()
        }, 5000)
        //$clearInterval(interval)
      })()
    }
  })
  */
  const getAppInfo = async (appId) =>
    await axios.get(`${algoexplorerapi_endpoint}/v2/applications/${appId}`)
  const somethingFromSome = (f, d) => (some) => some[1] ? f(some[1]) : d
  const bigNumberToNumberFromSome = (some) => somethingFromSome((some) => stdlib.bigNumberToNumber(some), 0)(some)
  // EVENTS
  const handleBank = async () => {

    // ALGO ONLY BEGIN
    if (acc.assets.filter(el => el['asset-id'] === ASSET_ID_JAM).length === 0) {
      console.log("Opting in to token JAM Coin")
      await acc.tokenAccept(ASSET_ID_JAM)
    }
    setOptin(true)
    // ALGO ONLY END

    setLoadingJam(true)
    let total = 1000
    if (!state.bank)
      try {
        await getAppInfo(APP_ID_BANK)
        const ctc = acc.attach(backendBank, APP_ID_BANK)
        await Promise.all([
          backendBank.Consumer(ctc, {
            getBid: () => new Promise(async (resolve) => {
              const viewAmount = bigNumberToNumberFromSome(await (await ctc.getViews()).Bank.amount())
              console.log({ viewAmount })
              console.log({ total })
              total -= 2
              if (total >= viewAmount) {
                resolve(false)
              } else {
                setLoadingJam(false)
                setState({
                  ...state,
                  viewAmount,
                  bank: ctc,
                  buy: resolve
                })
              }
            }),
            signal: () => {
              console.log("Signal!")
            },
            ...stdlib.hasConsoleLogger
          })])
      } catch (e) { }
  }
  const handleBuy = async () => !state.bank
    ? handleBank()
    : state.buy
    && state.buy(state.viewAmount === bigNumberToNumberFromSome(await (await state.bank.getViews()).Bank.amount()))

  // STYLE
  const navbarBrandNameStyle = {
    lineHeight: '62px',
    marginLeft: '8px'
  }
  const navbarBrandTypographyStyle =
  {
    "fontFamily": "Rubik Mono One",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "22px",
    "lineHeight": "27px"
  }
  const navbarLinkTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "700",
    "lineHeight": "17px",
    "letterSpacing": "0em",
    "textAlign": "center"
  }
  const navbarLinkStyle =
  {
    "color": "white",
    "textAlign": "center",
    "textTransform": "uppercase"
  }
  const navbarLinkWalletConnectStyle =
  {
    "display": "flex",
    "alignItems": "center"
  }
  const navbarLinkWalletConnectIconStyle =
  {
    "top": "-5px",
    "filter": "invert(1)",
    "height": "17px"
  }
  const navbarLinkWalletConnectLabelStyle =
  {
    "marginLeft": "7px"
  }
  const navbarStyle =
  {
    "padding": "26px",
    "color": "white"
  }
  const navbarCollapseStyle = {
    "justifyContent": "flex-end",
  }
  return <FeatureFlagContext.Consumer>
    {({
      sellPage,
      browsePage,
    }) =>
    <Navbar style={navbarStyle}>
      <Container>
        <Navbar.Brand className="text-light" onClick={() => history.push("/")}>
          <img
            src={logo}
            width="33"
            height="42"
            className="d-inline-block"
            alt="NFT Jam Logo"
          />
          <div className="d-inline-block" style={{ ...navbarBrandNameStyle, ...navbarBrandTypographyStyle }}>
            NFT Jam
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={navbarCollapseStyle}>
          <Nav style={{ columnGap: '2em', fontWeight: '500', "alignItems": "center" }}>
            {[
              /*
              {
                text: !loadingJam ? "Buy JAM" : <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ "height": "12px", "width": "12px" }} />
                  Loading...
                </>,
                onClick: handleBuy,
              },
              */
              {
                path: "/sell",
                text: "Sell NFT",
                className: location.pathname.indexOf('sell') !== -1 ? "active" : "",
                visible: sellPage
              },
              {
                path: acc?.address === props.ADDR_MNEMONIC ? `/auction` : `/auction/${acc?.address}`,
                text: "Auction",
                className: location.pathname.indexOf('auction') !== -1 ? "active" : "",
                visible: true
              },
              {
                path: '/browse',
                text: "Browse",
                className: location.pathname.indexOf('browse') !== -1 ? "active" : "",
                visible: browsePage
              },
              {
                path: "/faq",
                text: "FAQ",
                className: location.pathname.indexOf('faq') !== -1 ? "active" : "",
                visible: true
              },
            ]
            .filter(({visible}) => visible)
            .map(el =>
              <Nav.Link style={{ ...navbarLinkStyle, ...navbarLinkTypographyStyle }} className={[el.className, "d-none d-md-block"].join(" ")} onClick={el.onClick ? el.onClick : () => history.push(el.path)}>{el.text}</Nav.Link>
            )}
            {acc && acc?.address !== props.ADDR_MNEMONIC ? (
              <AccountInfo {...props} style={navbarLinkTypographyStyle} />
            ) : (
              <span role="button" onClick={handleConnect} style={{ ...navbarLinkStyle, ...navbarLinkTypographyStyle, ...navbarLinkWalletConnectStyle }}><img src={wallet} style={{ ...navbarLinkWalletConnectIconStyle }} alt="Algorand Wallet Icon" width="30" height="26" /><span style={{ ...navbarLinkWalletConnectLabelStyle }}>Connect <span className={"d-none d-md-inline-block"}>wallet</span></span></span>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>}
  </FeatureFlagContext.Consumer>
}

export default NFTJamNavbar;
