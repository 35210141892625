import moment from "moment"
import { useEffect } from "react"
import { Col, Image } from "react-bootstrap"
import Icon from "react-crypto-icons"
import { useHistory } from "react-router"
import { formatCompactAddress } from "../functions"
import useCopy from "../hooks/useCopy"
import Countdown from "./Countdown"
const GalleryCard = ({
  id,
  name,
  image,
  className,
  children,
  cp,
  endSecs,
  hb,
  owner,
  closed,
  isReservePriceMet,
  onClick,
  onLoaded,
}) => {
  const copy = useCopy()
  const history = useHistory()
  useEffect(() => {
    onLoaded()
  })
  const cardStyle =
  {
    //"background": "#FFFFFF",
    //"borderRadius": "36px",
    //"padding": "50px 56px",
    //"width": `${width || 565}px`,
    //"paddingBottom": "20px"
    color: 'white',
  }
  const compactCardStyle =
  {
    "top": "15%",
    "left": "5vw",
    "width": "90vw",
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "padding": "20px 30px",
    "marginTop": "0vh",
  }
  const titleTypographyStyle = {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "900",
    "fontSize": "32px",
    "lineHeight": "38px",
    "textAlign": "center",
    "letterSpacing": "0.1em",
    "textTransform": "uppercase",
    //"color": "#2A3035"
  }
  return <Col xs={3} className={className} style={cardStyle}>
      <div style={titleTypographyStyle}>{name}</div>
      <Image src={image} onClick={()=>onClick(id)} fluid/>
      <div style={{...titleTypographyStyle, "fill": "white"}}>
        <Icon name="algo" size={24} /><span style={{marginLeft: '5px', verticalAlign: 'middle'}}>{cp}</span>
      </div>
      <Countdown initialEndMoment={moment.unix(endSecs)} showBlocks={false} />
      <div>
        Auction id: {id}
      </div>
      {closed && <div>
        Auctioneer: <span style={{cursor: "pointer"}} onClick={()=>copy(owner)}>{formatCompactAddress(owner)}</span></div>}
      {closed && isReservePriceMet && <div>
        Winner: <span style={{cursor: "pointer"}} onClick={()=>copy(hb)}>{formatCompactAddress(hb)}</span>
      </div>}
      {children}
    </Col>
}

export default GalleryCard;